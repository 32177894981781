<template>
    <div>
<v-stepper-header>
        <v-stepper-step
          editable
          :complete="e1 > 2"
          step="1"
          :color="color"
        >
          Account Opening
        </v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step
          :editable ="editSteppers"
          :complete="e1 > 2"
          step="2"
          :color="color"
          
        >
           BioData
        </v-stepper-step>

</v-stepper-header>
    </div>
</template>

<script>
    export default {
     name:"ProStepper",
 data() {
     return {
    e1: 1,
    color: '#024202',
            }
        },
       
     computed: {
editSteppers(){
return true
},
},  
    }
</script>