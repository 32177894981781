<template>
  <div>
 <v-stepper-content step="1">
          <h3  class=" text-center mb-2">Account Opening</h3>
          <v-card
            class="mb-7"
            :color="cardColor"
            
          >
            <v-container>
              <v-form>
              <v-text-field 
              label="Hospital Number:" 
              :color="color"
              v-model="dform.hosp_no"
              :rules="[rules.required]"
              :readonly="!!dform.id && !edit.hosp_no"
              >
                <template v-if="dform.id" v-slot:append>
                  <v-btn icon @click="edit.hosp_no = !edit.hosp_no">
                    <v-icon v-if="edit.hosp_no">mdi-close</v-icon>
                    <v-icon v-else>mdi-pencil</v-icon>
                  </v-btn>
                </template>
              </v-text-field>

              <v-text-field 
              label="Pepfar Number:" 
              :color="color"
              v-model="dform.pepfar_no"
              :rules="[rules.required]"
              :readonly="!!dform.id && !edit.hosp_no"
              >
                <template v-if="dform.id" v-slot:append>
                  <v-btn icon @click="edit.pepfar_no= !edit.pepfar_no">
                    <v-icon v-if="edit.pepfar_no">mdi-close</v-icon>
                    <v-icon v-else>mdi-pencil</v-icon>
                  </v-btn>
                </template>
              </v-text-field>

               <v-text-field
                label="Date Enter Study:" 
                v-model="dform.study_entry_date"
                :color="color"
                :rules="[rules.required]"
                :readonly="!!dform.id && !edit.name"
                auto-grow
                rows="1"
              >
                <template v-if="dform.id" v-slot:append>
                  <v-btn icon @click="edit.study_entry_date= !edit.study_entry_date">
                    <v-icon v-if="edit.study_entry_date">mdi-close</v-icon>
                    <v-icon v-else>mdi-pencil</v-icon>
                  </v-btn>
                </template>
               </v-text-field>

               <v-text-field
                label="StudyNo:" 
                v-model="dform.studyno"
                :color="color"
                :rules="[rules.required]"
                :readonly="!!dform.id && !edit.studyno"
                auto-grow
                rows="1"
              >
                <template v-if="dform.id" v-slot:append>
                  <v-btn icon @click="edit.studyno = !edit.studyno">
                    <v-icon v-if="edit.studyno">mdi-close</v-icon>
                    <v-icon v-else>mdi-pencil</v-icon>
                  </v-btn>
                </template>
              </v-text-field>

              <v-radio-group dense row>
                <span class="mr-3">Entry Point: </span>
                <v-radio
                  label="PAEDIATRICS"
                  value="PAEDIATRICS"
                  color="green"
                ></v-radio>
                <v-radio
                  label="ADULTS"
                  value="ADULTS"
                  color="green"
                ></v-radio>
              </v-radio-group>

              </v-form>
            </v-container>
          </v-card>
          <v-card-actions>
            <v-btn
            small
            color="error" 
            >
            Cancel
            </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            small
            v-if="edit.hosp_no || edit.pepfar_no || edit.study_entry_date || edit.studyno"
            color="green lighten-1"
            @click="updateAccount(1, edit.hosp_no ? 'edit.hosp_no' :  edit.pepfar_no? ' edit.pepfar_no' : edit.study_entry_date ? 'edit.study_entry_date' : edit.studyno ? 'edit.studyno': '')"
            :loading="loading"
          >
            Save Changes
          </v-btn>
          <v-btn
            v-else
            small
            color="green lighten-1"
            :loading="loading"
            :disabled = "!!dform.id"
          >
            Create Account
          </v-btn>
          </v-card-actions>
       </v-stepper-content>
        

  </div>
</template>

<script>
  export default {
    name:"ProAccopen",

data() {
  return {
    color: '#024202',
      loading: false,
    cardColor: '#f0f7f0',
  rules: {required: value => !!value || 'Please fill this field.'},
  edit: {studyno: false, pepfar_no: false, hosp_no: false, study_entry_date: false},

dform: {
studyno: '',
pepfar_no:'',
study_entry_date: '',
hosp_no: '',  
 } 
      }
    },
    
    computed: {
editSteppers(){
return true
},
},

 created(){
if(this.editUser){
this.dform = {...this.editUser}
    }
  },


methods: {
         
      },  

  }
</script>
