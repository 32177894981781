<template>
    <div>
        <div  class="text-center">  <b>Personal Details</b></div>
<v-row>
            <v-col cols="12" md="6">
        <v-textarea
        v-model="dform.caregiver.address"
        :color="color"
        auto-grow
        rows="1"
        label="Address:"
        ></v-textarea> 
    </v-col>

    <v-col cols="12" md="3">
        <v-text-field
        v-model="dform.caregiver.pat_phoneno"
        label="Contact Phone Number (Father/Mother):"  
        :color="color"
        type="number"
        >
        </v-text-field>
    </v-col>

    <v-col cols="12" md="3">
        <v-text-field
        v-model="dform.caregiver.other_phoneno"
        label="Contact Phone Number (Others):" 
        :color="color"
        type="number">
        </v-text-field>
    </v-col>

        <v-col cols="12" md="4">
        <v-text-field
        v-model="dform.caregiver.pat_age"
        label="Age of Caregiver Father's age (Last birthday):" 
        type="number"
        :color="color">
        </v-text-field>
    </v-col>
    <v-col cols="12" md="4">
        <v-text-field
         v-model="dform.caregiver.mat_age"
        label="Age of Caregiver Mother's age (Last birthday):" 
        type="number"
        :color="color">
        </v-text-field>
    </v-col>
    <v-col cols="12" md="4">
        <v-text-field
         v-model="dform.caregiver.other_age"
        label="Age of Caregiver Other's age (Last birthday):" 
        type="number"
        :color="color">
        </v-text-field>
    </v-col>
        <v-col cols="12" md="3">
    <v-text-field 
     v-model="dform.caregiver.dob"
    type="date" 
    label="Date of Birth:" 
    :color="color" >
    </v-text-field>
    </v-col>
    <v-col cols="12" md="4">
        <v-radio-group   v-model="dform.caregiver.sex" dense row>
        <span class="mr-3">Sex: </span>
        <v-radio
        label="Male"
        value="Male"
        color="green"
        ></v-radio>
        <v-radio
        label="Female"
        value="Female"
        color="green"
        ></v-radio>
    </v-radio-group>
    </v-col>
        <v-col cols="12" md="5">
        <v-radio-group  v-model="dform.caregiver.tribe" dense row>
        <span class="mr-3">Tribe: </span>
        <v-radio
        label="Yoruba"
        value="Yoruba"
        color="green"
        ></v-radio>
        <v-radio
        label="Hausa"
        value="Hausa"
        color="green"
        ></v-radio>
        <v-radio
        label="Igbo"
        value="Igbo"
        color="green"
        ></v-radio>
        <v-radio
        label="Others"
        value="Others"
        color="green"
        ></v-radio>
    </v-radio-group>
    </v-col> 
        <v-col cols="12" md="4">
        <v-select
         v-model="dform.caregiver.mat_edu"
        :items="setData.education"
        label="Education Status of Mother:"
        :color="color"
        item-color="success"
        ></v-select>
        </v-col>
        <v-col cols="12" md="4">
        <v-select
         v-model="dform.caregiver.pat_edu"
        :items="setData.education"
        label="Education Status of Father:"
        :color="color"
        item-color="success"
        ></v-select>
        </v-col>
        <v-col cols="12" md="4">
        <v-select
         v-model="dform.caregiver.other_edu"
        :items="setData.education"
        label="Education Status of Others:"
        :color="color"
        item-color="success"
        ></v-select>
        </v-col>
        <v-col cols="12" md="4">
        <v-select
         v-model="dform.caregiver.mat_edu"
        :items="setData.marital"
        label="Marital Status of Mother:"
        :color="color"
        item-color="success"
        ></v-select>
        </v-col>
        <v-col cols="12" md="4">
        <v-select
         v-model="dform.caregiver.pat_edu"
        :items="setData.marital"
        label="Marital Status of Father:"
        :color="color"
        item-color="success"
        ></v-select>
        </v-col>
        <v-col cols="12" md="4">
        <v-select
         v-model="dform.caregiver.other_edu"
        :items="setData.marital"
        label="Marital Status of Others:"
        :color="color"
        item-color="success"
        ></v-select>
        </v-col>
        <v-col cols="12" md="4">
        <v-select
         v-model="dform.caregiver.mat_occupation"
        :items="setData.occupation"
        label="Occupation Of Mother:(specify)"
        :color="color"
        item-color="success"
        ></v-select>
    </v-col>
        <v-col cols="12" md="4">
        <v-select
         v-model="dform.caregiver.pat_occupation"
        :items="setData.occupation"
        label="Occupation Of Father:(specify)"
        :color="color"
        item-color="success"
        ></v-select>
    </v-col>
        <v-col cols="12" md="4">
        <v-select
         v-model="dform.caregiver.other_occupation"
        :items="setData.occupation"
        label="Occupation Of Others:(specify)"
        :color="color"
        item-color="success"
        ></v-select>
    </v-col>
        <v-col cols="12" md="6">
        <v-select
         v-model="dform.caregiver.marital"
        :items="setData.marital"
        label="Child's Marital Status:"
        :color="color"
        item-color="success"
        ></v-select>
    </v-col>
    
    <v-col cols="12" md="6" v-if="dform.caregiver.marital == 'Married'">
        <v-radio-group dense  v-model="dform.marital_status" row>
        <span class="mr-3">Family Structure:</span>
        <v-radio
        label="Monogamous"
        value="Monogamous"
        color="green"
        ></v-radio>
        <v-radio
        label="Polygamous"
        value="Polygamous"
        color="green"
        ></v-radio>
    </v-radio-group>
    </v-col> 
        </v-row>
    </div>
</template>

<script>
    export default {

        data() {
            return {
                color: '#024202',
 setData:{
marital:[{value:'', text:'Select...', disabled: true}, 'Not Yet Married','Married','Divorced','Separated','Widowed'],
education: [{value:'', text:'Select...', disabled: true},'Tertiary','Secondary',  'Primary', 'Koranic','None',],
occupation: [{value:'', text:'Select...', disabled: true},'Nil','Senior public servant, professional, manager,contractor, large scale trader','Intermediate grade, public servant, senior school teachers','Junior school teacher, driver, artisan','Petty trader, labourer, messenger, similar grades', 'Unemployment, fulltime house wife, student, subsistence farmer' 
],
 },
 dform:{
     caregiver:{},
 }
     }
            
        },
        
    }
</script>
