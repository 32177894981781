<template>
    <div>
        <v-stepper-content step="2">
          <v-card
            class="mb-8"
            :color="cardColor"
            >
            <v-card-title>
                <v-row>
                  <v-item-group v-model="window" mandatory> 
                    <v-item v-for="(n, m) in buttons" :key="m" v-slot="{ active, toggle}">
                    <v-btn 
                      class="mx-2"
                      x-small
                      color="light-green lighten-2"
                      :input-value="active"
                      @click="toggle"
                    >
                      {{ n }}
                    </v-btn>
                    </v-item>
                  </v-item-group>                  
                </v-row>
              </v-card-title>
              <v-window v-model="window">
                  <v-container fluid>

                       <v-window-item :value="0"> 
                       <carewindOne/>
                       </v-window-item>


                       <v-window-item :value="1"> 
                          <carewindTwo/>
                       </v-window-item>

                  </v-container>
              </v-window>
          </v-card>
           <v-card-actions>
            <v-btn
            color="error"
            small
            >
              Previous
            </v-btn>
            <v-spacer></v-spacer>
          <v-btn
            small
              color="green lighten-1">
              Save Changes
            </v-btn>
          </v-card-actions> 
        </v-stepper-content>
   
    </div>
</template>

<script>
import CarewindOne from '@/components/PROFORMA/CarewindOne.vue';
import CarewindTwo from '@/components/PROFORMA/CarewindTwo.vue';
    export default {
      name:"CaregiverData",

      components: {
        CarewindOne,
        CarewindTwo,

      },

        data() {
            return {
            enabled: false,
            window:0,
            color: '#024202',
            cardColor: '#f0f7f0',
            buttons: [1,2],

           
            }
            
       
        }
        
    }
</script>