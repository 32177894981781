<template>
    <div>
   <div  class="text-center">  <b>Screening Details</b></div>
        <v-row>
 <v-col cols="12" md="4">
    <v-radio-group v-model="dform.caregiver.hiv_screen" dense row>
    <span class="mr-3">Father's HIV Screening: </span>
    <v-radio
    label="Accepted"
    value="Accepted"
    color="green"
    ></v-radio>
    <v-radio
    label="Decline"
    value="Decline"
    color="green"
    ></v-radio>
    <v-radio
    label="Father not Available"
    value="Father not Available"
    color="green"
    ></v-radio>
</v-radio-group>
</v-col>

 <v-col cols="12" md="4">
    <v-radio-group dense v-model="dform.caregiver.screen_test" row>
    <span class="mr-3">Father's Screening Test: </span>
    <v-radio
    label="Elisa"
    value="Elisa"
    color="green"
    ></v-radio>
    <v-radio
    label="Elisa + WB"
    value="Elisa + WB"
    color="green"
    ></v-radio>
    <v-radio
    label="VAT (specify)"
    value="VAT"
    color="green"
    ></v-radio>
</v-radio-group>
</v-col>
<v-col cols="12" md="4" v-if="dform.caregiver.screen_test == 'VAT'">
<v-text-field
 label="specify:"
>
</v-text-field>
</v-col>
 <v-col cols="12" md="4">
    <v-radio-group dense v-model="dform.caregiver.screen_result" row>
    <span class="mr-3">Father's Screening Result: </span>
    <v-radio
    label="Reactive"
    value="Reactive"
    color="green"
    ></v-radio>
    <v-radio
    label="Non-reactive"
    value="Non-reactive"
    color="green"
    ></v-radio>
    <v-radio
    label="Indeterminate"
    value="Indeterminate"
    color="green"
    ></v-radio>
</v-radio-group>
</v-col>
 <v-col cols="12" md="4">
    <v-radio-group dense v-model="dform.caregiver.ward_disclose" row>
    <span class="mr-3">Have you Disclose to your ward: </span>
    <v-radio
    label="Yes"
    value="Yes"
    color="green"
    ></v-radio>
    <v-radio
    label="No"
    value="No"
    color="green"
    ></v-radio> 
</v-radio-group>
</v-col>
 <v-col cols="12" md="4">
    <v-radio-group v-model="dform.caregiver.handled_disclosure" dense row>
    <span class="mr-3">Who handled the disclosure: </span>
    <v-radio
    label="Caregiver"
    value="Caregiver"
    color="green"
    ></v-radio>
    <v-radio
    label="Health worker"
    value="Health worker"
    color="green"
    ></v-radio> 
    <v-radio
    label="Others (specify):"
    value="Others"
    color="green"
    ></v-radio> 
</v-radio-group>
</v-col>

<v-col cols="12" md="4" v-if="dform.caregiver.handled_disclosure == 'Others'">
<v-text-field
 label="Others:"
>
</v-text-field>
</v-col>

<v-col cols="12" md="4">
    <v-select
    v-model="dform.caregiver.handled_disclosureyears"
:items="years"
 label="Years disclosure handled:"
 color="green"
>
</v-select>
</v-col>

 <v-col cols="12" md="4">
    <v-radio-group dense v-model="dform.caregiver.partner_disclose" row>
    <span class="mr-3">Have you disclosed to your partner: </span>
    <v-radio
    label="Yes"
    value="Yes"
    color="green"
    ></v-radio>
    <v-radio
    label="No"
    value="No"
    color="green"
    ></v-radio> 
</v-radio-group>
</v-col>

<v-col cols="12" md="4">
    <v-select
    v-model="dform.caregiver.partner_disclosureyears"
:items="years"
 label="Years disclosure to partner:"
 color="green"
>
</v-select>
</v-col>
 <v-col cols="12" md="4">
    <v-radio-group dense v-model="dform.caregiver.ward_disclosurestatus" row>
    <span class="mr-3">Disclosure status to ward: pre OTZ </span>
    <v-radio
    label="Non-disclosure"
    value="Non-disclosure"
    color="green"
    ></v-radio>
    <v-radio
    label="Parial"
    value="Parial"
    color="green"
    ></v-radio> 
    <v-radio
    label="Full disclosure"
    value="Full disclosure"
    color="green"
    ></v-radio> 
</v-radio-group>
</v-col>
 <v-col cols="12" md="4">
    <v-radio-group v-model="dform.caregiver.missed_appointmentpre" dense row>
    <span class="mr-3">Missed appointment:  preOTZ </span>
    <v-radio
    label="None"
    value="none"
    color="green"
    ></v-radio>
    <v-radio
    label="1-2"
    value="one"
    color="green"
    ></v-radio> 
    <v-radio
    label="3-5"
    value="three"
    color="green"
    ></v-radio> 
    <v-radio
    label="5 or More"
    value="More"
    color="green"
    ></v-radio> 
</v-radio-group>
</v-col>
 <v-col cols="12" md="4">
    <v-radio-group v-model="dform.caregiver.missed_appointmentpost" dense row>
    <span class="mr-3">Missed appointment:   postOTZ </span>
    <v-radio
    label="None"
    value="none"
    color="green"
    ></v-radio>
    <v-radio
    label="1-2"
    value="one"
    color="green"
    ></v-radio> 
    <v-radio
    label="3-5"
    value="three"
    color="green"
    ></v-radio> 
    <v-radio
    label="5 or More"
    value="More"
    color="green"
    ></v-radio> 
</v-radio-group>
</v-col>

<v-col cols="12" md="5">
    <v-radio-group v-model="dform.caregiver.missed_drugspre" dense row>
    <span class="mr-3">Missed drugs: pre OTZ </span>
    <v-radio
    label="None"
    value="none"
    color="green"
    ></v-radio>
    <v-radio
    label="1-2"
    value="one"
    color="green"
    ></v-radio> 
    <v-radio
    label="3-5"
    value="three"
    color="green"
    ></v-radio> 
    <v-radio
    label="5 or More"
    value="More"
    color="green"
    ></v-radio> 
</v-radio-group>
</v-col>
<v-col cols="12" md="5">
    <v-radio-group v-model="dform.caregiver.missed_drugspost" dense row>
    <span class="mr-3">Missed drugs: post OTZ </span>
    <v-radio
    label="None"
    value="none"
    color="green"
    ></v-radio>
    <v-radio
    label="1-2"
    value="one"
    color="green"
    ></v-radio> 
    <v-radio
    label="3-5"
    value="three"
    color="green"
    ></v-radio> 
    <v-radio
    label="5 or More"
    value="More"
    color="green"
    ></v-radio> 
</v-radio-group>
</v-col>
</v-row>
<v-row>
    <v-col cols="12">
    <b>HAART Regimen preOTZ</b>
    </v-col>
<v-col cols="12" md="3">
    <v-text-field
    v-model="dform.caregiver.haartpredate"
    type="date"
    label="HAART Regimen preOTZ(date commenced):"
    color="green"
    >
  </v-text-field>
</v-col>
<v-col cols="12" md="4">
   <span><b>Anthropoimetry preOTZ: </b> </span> 
    <v-text-field
    label="weight (kg):"
     v-model="dform.caregiver.anthroweightpre"
     color="green"
    >  
    </v-text-field>
    <v-text-field
    label="height (ht)"
    v-model="dform.caregiver.anthroheightpre"
    color="green"
    >
    </v-text-field>
</v-col>
<v-col cols="12" md="4">
   <span><b>Anthropoimetry post OTZ: </b> </span> 
    <v-text-field
    label="weight (kg):"
    v-model="dform.caregiver.anthroweightpost"
    color="green"
    >  
    </v-text-field>
    <v-text-field
    label="height (ht)"
    v-model="dform.caregiver.anthroheightpost"
    color="green"
    >
    </v-text-field>
    <v-text-field
    label="BMI"
    v-model="dform.caregiver.anthrobmipost"
    color="green"
    >
    </v-text-field>
</v-col>
</v-row>
<v-row>
    <v-col cols="12">
    <b>HAART Regimen post OTZ</b>
    </v-col>
<v-col cols="12" md="3">
    <v-text-field
     v-model="dform.caregiver.haartpostdate"
    type="date"
    label="HAART Regimen post OTZ(date commenced):"
    color="green"
    >
  </v-text-field>
</v-col>
<v-col cols="12" md="4">
   <span><b>Anthropoimetry preOTZ: </b> </span> 
    <v-text-field
    label="weight (kg):"
    v-model="dform.caregiver.anthroweightpostpre"
    color="green"
    >  
    </v-text-field>
    <v-text-field
    label="height (ht)"
    v-model="dform.caregiver.anthroweightpostpre"
    color="green"
    >
    </v-text-field>
</v-col>
<v-col cols="12" md="4">
   <span><b>Anthropoimetry post OTZ: </b> </span> 
    <v-text-field
    label="weight (kg):"
    v-model="dform.caregiver.anthroweightpostpost"
    color="green"
    >  
    </v-text-field>
    <v-text-field
    label="height (ht)"
    v-model="dform.caregiver.anthroheightpostpost"
    color="green"
    >
    </v-text-field>
    <v-text-field
    label="BMI"
    v-model="dform.caregiver.anthrobmipostpost"
    color="green"
    >
    </v-text-field>
</v-col>
<v-col cols="12" md="3">
    <v-text-field
    v-model="dform.caregiver.viralloadpre"
    type="date"
    label="Last viral load pre OTZ:"
    color="green"
    >
  </v-text-field>
</v-col>
<v-col cols="12" md="3">
    <v-text-field
     v-model="dform.caregiver.viralloadpost"
    type="date"
    label="Last viral load post OTZ:"
    color="green"
    >
  </v-text-field>
</v-col>
<v-col cols="12" md="3">
    <v-text-field
     v-model="dform.caregiver.regimenpredate"
    type="date"
    label="HAART Regimen pre OTZ(date commenced):"
    color="green"
    >
  </v-text-field>
</v-col>
<v-col cols="12" md="3">
    <v-text-field
     v-model="dform.caregiver.regimenpostdate"
    type="date"
    label="HAART Regimen post OTZ(date commenced):"
    color="green"
    >
  </v-text-field>
</v-col>
<v-col cols="12" md="4">
    <v-text-field
     v-model="dform.caregiver.meetings"
    type="number"
    label="Total attendance of meetings before postOTZ assessment:"
    color="green"
    >
  </v-text-field>
</v-col>
</v-row>
    </div>
</template>

<script>
    export default {

    data() {
        return {
           years:['Less than 6 month', 'More than 7mths-1year', 'More than 1year -2 years', 'More than 3years'],
        dform:{
            caregiver:{},
        }
        }
    },
        
    }
</script>

<style lang="scss" scoped>

</style>