<template>
    <div>

      <h2 class="text-center">CAREGIVER DATA</h2>

<v-stepper v-model="e1" non-linear class="mt-6">

  
       <ProStepper/>

   <v-stepper-items>
         <ProAccopen/>
         <CaregiverData/> 
   </v-stepper-items>


  </v-stepper>
 
    </div>
</template>

<script>
   import ProStepper from '@/components/PROFORMA/ProStepper';
   import ProAccopen from '@/components/PROFORMA/ProAccopen';
   import CaregiverData from '@/components/PROFORMA/CaregiverData';
    export default {
       name:"Client",
        components: {
            ProStepper,
            ProAccopen,
            CaregiverData,
        },
        data() {
            return {
        e1:1,
        color: '#024202',
        loading: false,
        cardColor: '#f0f7f0',
            }
        },
        
    }
</script>

